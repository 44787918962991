<template>
  <!-- breadcrumb area start -->
  <!--
  <div
    class="breadcrumb-area"
    style="background-image: url(cdn/assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Our Products</h1>
            <ul class="page-list">
              <li><a href="#">Home</a></li>
              <li><h1>Products</h1></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
  <!-- breadcrumb area End -->

  <div class="blog-details-page pd-top-120">
    <div class="container">
      <div class="row custom-gutters-60">
        <div class="col-lg-12">
          <div class="single-blog-content">
            <div class="thumb">
              <img :src="this.blogimage" alt="blog" />
            </div>
            <div class="single-blog-details text-left">
              <h5>{{this.blogname}}</h5>
                  
              <p v-html="this.blogdescription"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      productdata: null,
      blogname: "",
      blofthumbnail: null,
      blogimage: null,
      blogtitle: null,
      blogsubtitle: null,
      blogsummary: null,
      blogdescription: "",
    };
  },
  methods: {
    goTo(url) {
      location.href = url;
    },
  },
  mounted() {
    var parmid = this.$route.params.id;
    // alert(this.$route.params.id);
    // Example GET request
    axios
      .get(
        "https://webainitnils.azurewebsites.net/app_getallblogbyid/?id=" +
          parmid
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        // Handle response data
        this.productdata = response.data.result[0];
        this.blogname = this.productdata.blogname;
        this.blogdescription = this.productdata.blogdescription;
        this.blogimage = this.productdata.blogimage;
        this.blofthumbnail = this.productdata.blofthumbnail;
        this.blogtitle = this.productdata.blogtitle;
        this.blogsubtitle = this.productdata.blogsubtitle;
        //alert('hi' + JSON.stringify(this.productdata));
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching JSON data:", error);
      });
  },
};
</script>
