<template>
  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div
            class="img-with-video img-with-video-2 wow animated fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div class="img-wrap">
              <img src="cdn/assets/img/startup/why-choose/1.png" alt="video" />
              <div class="hover">
                <a
                  href="https://www.youtube.com/watch?v=c7XEhXZ_rsk"
                  class="video-play-btn mfp-iframe"
                  ><img src="cdn/assets/img/we-provide/3.png" alt="video"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">
                We is the <span>New India Life Sciences ?</span>
              </h2>
              <p>
                Welcome to New India Life Sciences, where innovation,
                dedication, and a commitment to excellence converge to redefine
                the landscape of healthcare. As a pioneering force in the
                industry, we embody a vision of transformative healthcare
                solutions that transcend boundaries and empower individuals to
                lead healthier, more fulfilling lives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <!-- sbst service area start -->
  <div class="sbst-service-area pd-top-80">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="section-title text-center">
            <h2 class="title">About <span>Us</span></h2>
            <p>
              New India Life Sciences was founded with a vision to redefine
              healthcare through technology and innovation. Since our inception,
              we have been driven by a relentless determination to make a
              positive impact on the lives of individuals and communities. Our
              journey has been marked by milestones that reflect our unwavering
              dedication to excellence and our commitment to serving humanity.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="section-title col-xl-10 col-lg-10 text-left">
          <p>
            Welcome to New India Life Sciences, a pioneering healthcare company
            dedicated to revolutionizing the way people access and experience
            healthcare. With a steadfast commitment to innovation, quality, and
            affordability, we are at the forefront of delivering dynamic
            healthcare solutions to communities worldwide.
          </p>

          <p>
            Our Journey: New India Life Sciences was founded with a vision to
            redefine healthcare through technology and innovation. Since our
            inception, we have been driven by a relentless determination to make
            a positive impact on the lives of individuals and communities. Our
            journey has been marked by milestones that reflect our unwavering
            dedication to excellence and our commitment to serving humanity.
          </p>

          <p>
            Technology and Execution: At New India Life Sciences, we believe in
            harnessing the power of technology to enhance healthcare delivery.
            Our products exemplify the integration of cutting-edge technology
            and meticulous execution, ensuring optimal outcomes for patients.
            Through continuous research and development, we strive to stay ahead
            of the curve, offering innovative solutions that address the
            evolving healthcare needs of our customers.
          </p>

          <p>
            Community Health: Our primary goal at New India Life Sciences is to
            empower communities to lead healthier lives. We recognize the
            importance of access to high-quality and affordable medicines,
            especially for those in need. Through our initiatives and
            partnerships, we aim to bridge the gap in healthcare access,
            ensuring that no one is left behind. By focusing on preventive care
            and wellness, we aspire to create a healthier future for generations
            to come.
          </p>

          <p>
            Trusted by Professionals and Patients: Over the years, New India
            Life Sciences has earned the trust of healthcare professionals and
            patients alike. Our commitment to quality and reliability has
            established us as a reputable name in the healthcare industry.
            Whether it&#39;s a physician prescribing our medicines or a patient
            relying on our products, they can trust in the efficacy and safety
            of New India Life Sciences.
          </p>

          <p>
            Market Presence: With our relentless commitment and innovative
            approach, New India Life Sciences has made a notable presence in the
            market. Our products are widely recognized for their superior
            quality and effectiveness, garnering accolades from both customers
            and industry experts. Through strategic expansion and partnerships,
            we continue to strengthen our foothold in the global healthcare
            landscape.
          </p>

          <p>
            Vision: Our vision at New India Life Sciences is ambitious yet
            achievable - to be among the leading healthcare companies worldwide.
            We aspire to set new benchmarks in healthcare delivery by leveraging
            technology and innovation to meet the diverse needs of humanity.
          </p>

          <p>
            Mission: Our mission is rooted in our core values of dynamism,
            scientific rigor, cost-effectiveness, and quality. We are committed
            to providing innovative healthcare solutions that empower
            individuals and communities to live healthier, more fulfilling
            lives.
          </p>

          <p>
            Values: At New India Life Sciences, our values serve as the guiding
            principles that drive our actions and decisions:
          </p>

          <p>
            Quality: We are dedicated to delivering products and services of the
            highest quality, exceeding the expectations of our customers and
            stakeholders.
          </p>

          <p>
            Innovation: We embrace innovation as a catalyst for positive change,
            continuously seeking new ways to improve healthcare outcomes and
            experiences.
          </p>

          <p>
            Integrity: We conduct ourselves with honesty, transparency, and
            ethical integrity in all our endeavors, earning the trust and
            respect of our stakeholders.
          </p>

          <p>
            Customer Focus: We prioritize the needs and preferences of our
            customers, striving to deliver personalized and value-driven
            solutions that meet their expectations.
          </p>

          <p>
            Teamwork: We foster a culture of collaboration and teamwork,
            recognizing that our collective efforts are essential to achieving
            our shared goals and objectives.
          </p>

          <p>
            Conclusion: At New India Life Sciences, we are committed to shaping
            the future of healthcare through innovation, integrity, and
            excellence. By staying true to our values and vision, we aim to make
            a meaningful difference in the lives of individuals and communities
            around the world. Join us on our journey as we continue to empower
            health and transform lives for the better.
          </p>
        </div>
      </div>
      <!--
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="section-title text-center">
            <h2 class="title">Vision</h2>
            <p>
              New India Life Sciences vision is to be among the leading
              healthcare company which uses or comprises technology and
              innovation to cater the everyday needs of human beings.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="section-title text-center">
            <h2 class="title">Mission</h2>
            <p>
              To provide Dynamic, Scientific, Cost Effective and Quality
              Healthcare solutions to the global community through innovation.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="section-title text-center">
            <h2 class="title">Values</h2>
            <p>
              Our values reflect who we are and what we stand for as a company.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="section-title text-center">
            <h2 class="title">Quality</h2>
            <p>
              We provide outstanding products and unsurpassed service that,
              together, deliver premium value to our customers.
            </p>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <!-- sbst service area end -->
</template>
