<template>
  <div>
    <!-- footer area start -->
    <footer
      class="footer-area footer-area-2 pd-top-120"
      style="background-image: url(cdn/assets/img/bg/footer.png)"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-11 text-center">
            <div class="section-title text-center">
              <h2 class="title">Get our latest <span>Updates</span></h2>
              <!--<p>Offer your business with the best assistance for growth.</p>-->
            </div>
            <div class="newsletter-subcribe">
              <form id="news-subcribeform" class="subcribe-form">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your E-mail..."
                    name="mail"
                    required=""
                  />
                  <button type="submit" class="btn-blue subcribe-submit">
                    submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="footer-widget-area mg-top-50 text-center">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="footer-widget widget">
                <div class="about_us_widget newcenter">
                  <a href="#" class="footer-logo">
                    <img src="cdn/assets/img/logo.png" alt="footer logo" />
                  </a>
                  <p>
                    New India life Sciences <br />Plot No. 04, Teachers Enclave,
                    Near Rathore Petrol Pump, 100 Feet Road <br />Bareilly ( UP)
                    243122
                  </p>
                  <address>
                    <a href="info@newindialifesciences.in"
                      >info@newindialifesciences.in</a
                    >
                  </address>

                  <ul class="social-icon">
                    <li>
                      <a
                        class="facebook"
                        href="https://www.facebook.com"
                        target="_blank"
                        ><i class="fa fa-facebook"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        class="twitter"
                        href="https://twitter.com"
                        target="_blank"
                        ><i class="fa fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        class="linkedin"
                        href="https://www.linkedin.com"
                        target="_blank"
                        ><i class="fa fa-linkedin"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        class="pinterest"
                        href="https://www.instagram.com"
                        target="_blank"
                        ><i class="fa fa-instagram"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-inner">
        <div class="copyright-text">
          &copy; {{ currentYear }} New India Life Sciences. All rights reserved.
        </div>
      </div>
    </footer>
    <!-- footer area end -->
    <!-- back to top area start -->
    <div class="back-to-top">
      <span class="back-top"><i class="fa fa-angle-up"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
.newcenter {
  text-align: center !important;
}
</style>