<template>
  <!-- breadcrumb area start -->
  <!--
<div class="breadcrumb-area" style="background-image:url(cdn/assets/img/page-title-bg.png);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb-inner">
                    <h1 class="page-title">Contact</h1>
                    <ul class="page-list">
                        <li><a href="#" to="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>-->
  <!-- breadcrumb area End -->
  <div class="client-area pd-top-112">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-9">
          <div class="section-title style-two text-center">
            <h2 class="title">Our <span>Creative Team</span></h2>
            <p>
              At New India Life Sciences, we believe in the power of teamwork,
              innovation, and relentless pursuit of excellence. Our team
              comprises individuals who are not just passionate about their
              fields but are also committed to making a positive impact on
              society. Let's meet the driving forces behind our organization
            </p>
          </div>
        </div>
      </div>
      <div class="client-slider">
        <div class="client-slider-item">
          <div class="media">
            <img src="cdn/assets/img/client/1.png" alt="client" />
            <div class="media-body">
              <h6>Mrs. Kavita Rani</h6>
              <p>Director</p>
            </div>
          </div>
        
            <p class="client-content">Mrs. Kavita Rani, our esteemed Director, is the heartbeat of our organization, bringing with her a master&#39;s degree in commerce and an unparalleled energy for progress. Her leadership style is defined by a potent mix of determination, strategic insight, and genuine compassion. Mrs. Rani&#39;s ability to set and achieve audacious goals is unmatched, driving our team with enthusiasm and precision. Her exceptional communication and marketing skills are not just assets but catalysts for inspiration, galvanizing us to exceed expectations.</p>

  <p class="client-content">What sets Mrs. Rani apart is her profound commitment to societal betterment. Her dream of making quality medicine accessible to all fuels our collective mission to create positive change. With Mrs. Rani&#39;s guidance, we are not merely a business; we are agents of transformation, working towards a brighter future for India and beyond. Her vision shapes our endeavors, her determination propels us forward, and her compassion fuels our purpose. Mrs. Rani&#39;s leadership is not just a privilege but a driving force that inspires us to reach for greatness every day.</p>

        </div>
        <div class="client-slider-item">
          <div class="media">
            <img src="cdn/assets/img/client/2.png" alt="client" />
            <div class="media-body">
              <h6>Dr. Ashutosh Kumar Singh</h6>
              <p>Director</p>
            </div>
          </div>
          <p class="client-content">
            Dr. Ashutosh Kumar Singh completed his M.B.B.S degree from KGMU
            Lucknow in 2009. With a distinguished career marked by a deep
            understanding and extensive experience in Medical Sciences, Dr.
            Singh stands as a beacon of leadership in the field.
          </p>
          <p class="client-content">
            As a young leader in medical science, he possesses an unwavering
            ardor to turn his vision into reality. His remarkable energy has
            paved the way to conquer all goals set forth. Dr. Singh is a
            reservoir of technical astuteness, with disciplined business acumen
            and unmatched foresight that reverberates throughout the Medical
            Sciences Industry.
          </p>
          <p class="client-content">
            He holds the pivotal role of charting the Company’s strategic
            planning and driving business development growth in both domestic
            and overseas markets. Dr. Singh's contributions continue to shape
            and propel New India Life Sciences towards unprecedented success.
          </p>
        </div>
        <div class="client-slider-item">
          <div class="media">
            <img src="cdn/assets/img/client/3.png" alt="client" />
            <div class="media-body">
              <h6>Mr. Prateek Tripathi</h6>
              <p>Director</p>
            </div>
          </div>
          <p class="client-content">
            Mr. Prateek Tripathi, our Director, embarked on his journey after
            attaining a degree in engineering. He honed his skills through
            associations with renowned companies such as Bajaj, Omega Ice Hill,
            and more. His illustrious career is marked by a deep understanding
            and rich experience in various aspects of the market business.
          </p>
          <p class="client-content">
            Under Mr. Tripathi’s dynamic leadership, New India Life Sciences has
            surged ahead, gaining strong momentum in its home markets. His
            leadership has augmented the company's capabilities, strengthened
            its core, and led to significant improvements in its operating
            margin and profitability.
          </p>
          <p class="client-content">
            As a young entrepreneur, Mr. Tripathi has infused the organization
            with a new vision and passion. He spearheaded a 360-degree shift
            from conventional markets to a global platform, demonstrating his
            commitment to innovation and growth. His prolific leadership has
            propelled the Company’s India market business to unparalleled
            heights, marking a new era of success and achievement.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goTo(url) {
      location.href = url;
    },
  },
};
</script>