import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import ProductsView from '../views/ProductsView.vue'
import ProductsDetailView from '../views/ProductsDetailView.vue'
import OurTeam from '../views/OurTeam.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/about',
    name: 'about',
    component: AboutView
  }
  ,
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUsView
  }, {
    path: '/ourproduct',
    name: 'ourproduct',
    component: ProductsView
  }, {
    path: '/productdetail/:id',
    name: 'productdetail',
    component: ProductsDetailView
  }
  , {
    path: '/ourteam',
    name: 'ourteam',
    component: OurTeam
  }
  ,
  {
    path: '/aboutus',
    name: 'aboutus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }

]


const router = createRouter({
  history: createWebHashHistory(),

  routes
})



export default router
