<template>
  <!-- preloader area start -->
  <div class="preloader" id="preloader">
    <div class="preloader-inner">
      <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
  </div>
  <!-- preloader area end -->
  <!-- search Popup -->
  <div class="body-overlay" id="body-overlay"></div>
  <div class="search-popup" id="search-popup">
    <form action="#" class="search-form">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Subscribe....." />
      </div>
      <button type="submit" class="submit-btn">
        <i class="fa fa-search"></i>
      </button>
    </form>
  </div>
  <!-- //. search Popup -->
  <!-- navbar area start -->
  <nav class="navbar navbar-area navbar-expand-lg nav-style-01">
    <div class="container nav-container">
      <div class="responsive-mobile-menu">
        <div class="logo-wrapper mobile-logo">
          <a href="index#" class="logo">
            <img src="cdn/assets/img/logo.png" alt="logo12" />
          </a>
        </div>
        <div class="nav-right-content">
          <ul>
            <li class="search">
              <i class="ti-search"></i>
            </li>
          </ul>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#nit_main_menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggle-icon">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="nit_main_menu">
        <div class="logo-wrapper desktop-logo">
        
             <router-link to="/"  class="logo"> <img src="cdn/assets/img/logo.png" alt="logo" /></router-link>
           
          
        </div>
        <ul class="navbar-nav">
          <li class="menu-item-has-children">
            <router-link to="/">Home</router-link>
            <!--<ul class="sub-menu">
                         <li><a href="index#">Software Home</a></li>
                         <li><a href="home02#">App Home</a></li>
                         <li><a href="home03#">Startup Company</a></li>
                         <li><a href="home04#">Management software</a></li>
                         <li><a href="home05#">Tech-company</a></li>
                         <li><a href="home06#">New Technology</a></li>
                         <li><a href="home07#">Digital Marketing</a></li>
                         <li><a href="home08#">Pos Device</a></li>
                         <li><a href="home09#">Hosting</a></li>
                     </ul>-->
          </li>
          <li class="menu-item-has-children">
            <!--<a href="#" @click="goTo('/about')">AboutUs</a>-->
            <router-link to="/about">About</router-link>
            <!--<ul class="sub-menu">
                         <li><a href="shop#">Shop</a></li>
                         <li><a href="shop-grid#">Shop Grid</a></li>
                         <li><a href="shop-details#">Shop Details</a></li>
                         <li><a href="checkout#">Checkout</a></li>
                     </ul>-->
          </li>
          <li class="menu-item-has-children">
           
               <router-link to="/ourteam">Our Team</router-link>
            <!--<ul class="sub-menu">
                         <li><a href="blog#">Blog</a></li>
                         <li><a href="blog-grid#">Blog Grid</a></li>
                         <li><a href="blog-details#">Blog Details</a></li>
                     </ul>-->
          </li>
          <li class="menu-item-has-children">
           
             <router-link to="/ourproduct">Products</router-link>
            
          </li>
          
          <li>
            <!--<a href="contact#">Contact</a>-->
             <router-link to="/contactus">Contact</router-link>
          </li>
          <li></li>
        </ul>
      </div>
      <div class="nav-right-content">
        <ul>
          <li class="search">
            <i class="ti-search"></i>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- navbar area end -->
</template>

<script>
export default {
name: 'app-header',
}
</script>

<style>

</style>